








































































import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppCheckbox from "@/components/core/AppCheckbox.vue";
import AppConfirmModal from "@/components/modals/AppConfirmModal.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppMember from "@/components/AppMember.vue";
import AppMemberAddModal from "@/components/modals/AppMemberAddModal.vue";
import AppSearch from "@/components/core/AppSearch.vue";
import { removeMember, searchMembers } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppCheckbox,
    AppConfirmModal,
    AppListGroup,
    AppListGroupItem,
    AppMember,
    AppMemberAddModal,
    AppSearch,
  },
  methods: {
    removeMember,
    searchMembers,
  },
})
export default class AppMemberList extends Vue {
  memberPendingRemoval: pro.Member | null = null;
  membersRemoved: pro.Member[] = [];
  membersAdded = false;
  notification: Notification | null = null;
  showAddMemberModal = false;
  saving = false;

  get catalogId() {
    return Number(this.$route.params.catalogId);
  }

  /**
   * Removes a Member from a Catalog
   */
  async removeMember(): Promise<void> {
    this.saving = true;
    this.notification = null;
    const member = this.memberPendingRemoval;
    if (!member) return;
    try {
      await removeMember({ id: this.catalogId }, member);
      this.membersRemoved.push({ ...member });
    } catch (err) {
      this.notification = createErrorNotification(
        `Unable to remove ${member?.user?.fullName} from the catalog`,
        err.message
      );
    } finally {
      this.memberPendingRemoval = null;
      this.saving = false;
    }
  }

  memberRemoved(member: pro.Member): boolean {
    return this.membersRemoved.some(el => el?.user?.id == member?.user?.id);
  }

  refresh(): void {
    this.membersAdded = false;
    this.membersRemoved = [];
  }
}
