









































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppInputGroup from "@/components/core/AppInputGroup.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { regenerateCatalogAccessCode } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppInput,
    AppInputGroup,
    AppLabel,
    AppWidget,
  },
})
export default class AppMemberInviteWidget extends Vue {
  @Prop({ type: Object, required: true })
  readonly catalog!: pro.Catalog;

  notification: Notification | null = null;
  saving = false;

  /**
   * Regenerates the Catalog access code
   */
  async regenerateAccessCode(): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      const catalog = await regenerateCatalogAccessCode(this.catalog);
      this.catalog.accessCode = catalog.accessCode;
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to regenerate access code",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  /**
   * Generates the Catalog's invitation link using the Catalog's access code
   */
  invitationLink(): string {
    const origin = process.env.VUE_APP_ORIGIN;
    return `${origin}/catalogs/join/${this.catalog.accessCode}`;
  }

  /**
   * Copies the Catalog access code to the clipboard
   */
  copyToClipboard(): void {
    navigator.clipboard.writeText(this.invitationLink());
  }
}
