


















































import { Component, Prop, Vue } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppModal from "@/components/core/AppModal.vue";
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
  Notification,
} from "@/utils/notification";
import { addMember } from "@/utils/api";

@Component({
  components: {
    AppAlert,
    AppButton,
    AppInput,
    AppLabel,
    AppModal,
  },
})
export default class AppAddMemberModal extends Vue {
  @Prop({ required: true })
  readonly catalogId!: pro.Id;

  emailAddress = "";
  notification: Notification | null = null;
  saving = false;

  mounted() {
    this.notification = createInfoNotification(
      "Enter the email address of the user you'd like to add to the catalog"
    );
  }

  async addMember(): Promise<void> {
    try {
      this.saving = true;
      await addMember({ id: this.catalogId }, this.emailAddress);
      this.notification = createSuccessNotification(
        "Success",
        "The user is now a member of the catalog."
      );
      this.$emit("member-added");
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to add member",
        err.message
      );
    } finally {
      this.emailAddress = "";
      this.saving = false;
      const el: HTMLElement | null = document.querySelector(
        "#newMemberEmailAddress"
      );
      if (el) el.focus();
    }
  }
}
