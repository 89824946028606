
































import { Component, Prop, Vue } from "vue-property-decorator";
import AppBadge from "@/components/core/AppBadge.vue";
import AppMemberList from "@/components/AppMemberList.vue";
import AppMemberInviteWidget from "@/components/widgets/AppMemberInviteWidget.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { findCatalog } from "@/utils/api";
import { hasAdminPermissions } from "@/utils/authorization";

@Component({
  components: {
    AppBadge,
    AppMemberInviteWidget,
    AppMemberList,
    TheHeader,
    TheMain,
  },
})
export default class Catalog extends Vue {
  @Prop({ required: false })
  readonly initialCatalog!: pro.Catalog;

  isAdmin = false;
  catalog: pro.Catalog = {};
  saving = false;

  get catalogId() {
    return Number(this.$route.params.catalogId);
  }

  async mounted() {
    const catalog = { id: this.catalogId };
    this.isAdmin = await hasAdminPermissions(catalog);
    await this.loadCatalog();
  }

  async loadCatalog(): Promise<void> {
    this.catalog =
      this.initialCatalog ?? (await findCatalog({ id: this.catalogId }));
  }
}
