












































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import { updateMember } from "@/utils/api";
import { CATALOG_ROLES } from "@/main";
import { createErrorNotification } from "@/utils/notification";
import { isCurrentUser } from "@/utils/authentication";

@Component({ components: { AppButton }, methods: { isCurrentUser } })
export default class AppMember extends Vue {
  @Prop({ required: false })
  readonly catalog!: pro.Catalog;

  @Prop({ required: false })
  readonly initialMember!: pro.Member;

  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: Boolean, default: false })
  readonly isHeader!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly deleted!: boolean;

  member: pro.Member | null = null;

  mounted() {
    if (this.initialMember) this.member = this.initialMember;
  }

  /**
   * Generates a unique checkbox ID
   */
  checkboxId(role: pro.CatalogRole): string {
    return `${this.id}-${role}`;
  }

  /**
   * Fetches a list of valid catalog roles
   */
  catalogRoles() {
    return Object.values(CATALOG_ROLES);
  }

  /**
   * Parses a Catalog Role into a readable format
   */
  readableCatalogRole(role: string) {
    role = role.toLowerCase();
    return role.charAt(0).toUpperCase() + role.slice(1);
  }

  /**
   * Updates a Member's catalog roles
   */
  async updateMemberRoles(): Promise<void> {
    this.$emit("notification", null);
    try {
      await updateMember(this.catalog, this.member ?? {});
    } catch (err) {
      this.$emit(
        "notification",
        createErrorNotification("Unable to update member roles", err.message)
      );
    }
  }

  /**
   * Checks whether a Member has a role
   */
  hasRole(member: pro.Member, role: pro.CatalogRole): boolean {
    if (!member.roles) return false;
    return member.roles.includes(role);
  }
}
